<template>
    <div class="dailyWord_detail">
        <div class="mian">
            <div class="inner_nav clearfix">
                <div class="fl inner_nav_l">当前位置：<span><router-link to="/dailyWord">每日一字</router-link></span> > <router-link to="/dailyWord">每日一字上传</router-link> > {{info.title}}</div>
            </div>
        </div>
        <div class="mian">
            <div class="clearfix">
                <div class="fl ranking_detail_info dailyWord_detail_l">
                    <div class="ranking_detail_info_top clearfix">
                        <div class="fl detail_info_top_l">
                            <div class="detail_info_top_l_name">{{info.title}}</div>
                            <div class="detail_info_top_l_dec">上传时间：{{info.time}}</div>
                        </div>
                        <div class="fr detail_info_top_r">
                            <div class="fl">分享到：</div>
                            <img class="fl" @click="$share.toQQ(info.title)" src="../assets/images/detailqq.png" alt="">
                            <img class="fl" @click="$share.toWeibo(info.title)" src="../assets/images/detailweibo.png" alt="">
                            <div class="fl wxShare">
                                <img src="../assets/images/detailweixin.png" alt="">
                                <div class="wxShare_box">
                                    <div>使用微信 扫一扫</div>
                                    <div id="qrcode" ref="qrcode"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ranking_detail_info_img">
                        <img v-lazy="info.thumb" :key="info.thumb" alt="">
                    </div>
                </div>
                <div class="fr dailyWord_detail_r">
                    <div class="dailyWord_detail_r_user">
                        <img class="dailyWord_detail_user_img" v-lazy="info.user.thumb?info.user.thumb:''" alt="">
                        <div class="dailyWord_detail_user_name">{{info.user.username}}</div>
                        <p class="dailyWord_detail_user_dec">{{info.user.describe?info.user.describe:'这个人很懒，什么也没有留下...'}}</p>
                        <div class="dailyWord_detail_user_fans">
                            人气 {{info.user.mood}}<span></span>粉丝 {{info.user.fans_num}}
                        </div>
                        <div class="dailyWord_detail_user_button">
                            <div @click="$parent.getUser(info.userid)">
                                个人主页
                                <!-- <router-link :to="{path:'user',query:{userId:info.userid}}">个人主页</router-link> -->
                            </div>
                            <div @click="followFun" :class="info.user.is_follow == 1? 'isfollow':''">
                                <img v-if="info.user.is_follow == 0" src="../assets/images/dailyguanzhu.png" alt="">
                                <img v-if="info.user.is_follow == 1" src="../assets/images/guanzhu02@2x.png" alt="">
                                {{info.user.is_follow == 1? '已关注':'关注'}}
                            </div>
                        </div>
                    </div>
                    <div class="dailyWord_detai_works">
                        <div class="dailyWord_detai_works_title">Ta 的作品</div>
                        <div class="dailyWord_detai_works_img">
                            <template v-for="(item,index) in info.user.task">
                                <div :key="index" v-if="index<2">
                                    <img :src="item.thumb" @click="toDetail(item.id)" alt="">
                                </div>
                            </template>
                            
                        </div>
                        <div class="dailyWord_detai_works_more" @click="$parent.getUser(info.userid)">查看更多</div>
                    </div>
                </div>
            </div>
        </div>
         <!-- 猜你喜欢 -->
        <div class="mian">
            <div class="public_title">
                <div class="public_title_l public_title_ls">
                    <div class="public_title_name">猜你喜欢</div>
                </div>
            </div>
            <div class="ranking_ul clearfix" v-if="list.length>0">
                <!-- wow animate__animated animate__fadeInUp -->
                <div class="fl daily_li " :style="{animationDelay:0.1*index+'s'}" v-for="(item,index) in list" :key="index" @click="toDetail(item.id)">
                    <div class="ranking_li_box">
                        <img v-lazy="item.thumb" alt="">
                    </div>
                    <div class="ranking_li_name">{{item.title}}</div>
                    <div class="clearfix">
                        <div class="fl dailyWord_li_l" @click="$parent.getUser(item.userid)">
                            <img class="fl" :src="item.user.thumb" alt="">
                            <span>{{item.user.username}}</span>
                        </div>
                        <div class="fr dailyWord_li_r">{{item.last_time}}</div>
                    </div>
                </div>
            </div>
            <el-empty v-else description="暂无数据"></el-empty>
        </div>
        <!-- 侧边导航 -->
        <sidebar ref="downtotal"></sidebar>
    </div>
</template>

<script>
const wx = require('weixin-js-sdk')
import QRCode from 'qrcodejs2'
// import { WOW } from 'wowjs'
import sidebar from '../components/sidebar.vue'
export default {
    name: "dailyWordDetail",
    components:{
        sidebar
    },
    data(){
        return{
            info:{},//详情

            list:[],//最新上榜

            id:'',
        }
    },
    mounted(){
        this.$parent.routerIndex = 5;
        this.$parent.showIndex = 1;
        // new WOW().init();
        if(this.$route.query.id){
            this.id = this.$route.query.id
            this.initFun(this.$route.query.id)
        }
        this.$refs.downtotal.listFun()
    },
    methods:{
        initFun(id){
            var that = this;
            var params ={
                id:id,
            }
            this.$api.POST(this.$face.fontTaskInfo,params,function(res){
                that.info = res.data;
                
                if (that.info.user.thumb.indexOf("http") == -1) {
            that.info.user.thumb = require("../assets/images/head.png");
          }
                that.info.time = that.timeFun(that.info.create_time)
                that.list = res.data.like;
                for(let i of that.list){
                if (i.user.thumb.indexOf("http") == -1) {
                            i.user.thumb = require("../assets/images/head.png");
                        }
                }
                


                that.$nextTick(() => {
                    let qrcode = new QRCode('qrcode',{
                        width: 100, // 设置宽度，单位像素
                        height: 100, // 设置高度，单位像素
                        text: 'https://m.zitisheji.com/#/pages/mycenter/uni_worddetail?id='+that.id // 设置二维码内容或跳转地址
                    })
                })
            })
        },
        //时间处理
        timeFun(el){
            var arry = el.substring(0,10).split('-');
            return arry.join('/')
        },

        // 查看详情
        toDetail(id){
            localStorage.setItem('clearAll',2)
            //this.$router.push({name:'refresh',query:{id:id}})
            let routeUrl = this.$router.resolve({
				name: "dailyWordDetail",
				query: {
					id: id
				}
			});
			window.open(routeUrl.href, '_blank');
        },

        //关注
        followFun(){
            var that = this;
            var params ={
                id: this.info.userid,
            }
            this.$api.POST(this.$face.font_followFollow,params,function(res){
                if(that.info.user.is_follow == 1){
                    that.info.user.is_follow = 0
                }else{
                    that.info.user.is_follow = 1
                }
                that.initFun(that.$route.query.id)
                that.$utile.prompt('success',res.msg);
            })
        }
    },
    watch:{
        
    }
};
</script>

<style scoped>
.dailyWord_detail{
    background-color: #f6f6f6;
}

.inner_nav {
    padding: 27px 0 17px;
}
.inner_nav_l {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 12px;
    letter-spacing: 0px;
    color: #999999;
}


.ranking_detail_info{
    width: 100%;
    background: #FFF;
    border-radius: 10px;
    padding: 0 31px 0 30px;
    box-sizing: border-box;
    min-height: 600px;
    margin-bottom: 59px;
}
.dailyWord_detail_l{
    width: 1097px;
}

.ranking_detail_info_top{
    border-bottom: 1px solid #eeeeee;
}
.detail_info_top_l{
    padding: 43px 0 26px;
}
.detail_info_top_l_name{
    font-size: 24px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 24px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 18px;
}
.detail_info_top_l_dec{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
}
.detail_info_top_r{
    padding-top: 67px;
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 22px;
	letter-spacing: 0px;
	color: #999999;
    
}
.detail_info_top_r img{
    margin: 0 6px;
}
.detail_info_top_r img:last-child{
    margin-right: 0;
}

.ranking_detail_info_img{
    padding: 33px 0 40px;
}
.ranking_detail_info_img img{
    max-width: 1000px;
    margin: 0 auto 14px;
}


.dailyWord_detail_r{
    width: 284px;
    height: 865px;
    background: #FFF;
    border-radius: 10px;
    padding: 33px 22px;
    box-sizing: border-box;
}
.dailyWord_detail_r_user{
    text-align: center;
    padding-bottom: 36px;
    border-bottom: 1px solid #eeeeee;
}
.dailyWord_detail_user_img{
    width: 80px;
    height: 80px;
    border-radius: 80px;
    margin: 0 auto 29px;
}
.dailyWord_detail_user_name{
    font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 18px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 12px;
}
.dailyWord_detail_user_dec{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
    margin-bottom: 21px;
}
.dailyWord_detail_user_fans{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
    margin-bottom: 38px;
}
.dailyWord_detail_user_fans span{
    display: inline-block;
    background-color: #ccc;
    width: 1px;
    height: 11px;
    margin: 0 12px;
}
.dailyWord_detail_user_button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.dailyWord_detail_user_button>div{
    width: 105px;
	height: 40px;
	background-color: #ffffff;
	border-radius: 10px;
	border: solid 1px #eeeeee;
    box-sizing: border-box;
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 38px;
	letter-spacing: 0px;
	color: #999999;
    margin: 0 6px;
    cursor: pointer;
}
.dailyWord_detail_user_button>div:nth-child(2){
	background-color: #5957FF;
	border: solid 1px #5957FF;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFF;
}
.dailyWord_detail_user_button>div:nth-child(2) img{
    margin-right: 4px;
}
.dailyWord_detail_user_button>div.isfollow{
    background-color: #f6f6f6;
	border: solid 1px #eeeeee;
    color: #999999;
}

.dailyWord_detai_works{
    padding-top: 41px;
}
.dailyWord_detai_works_title{
    font-size: 18px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 18px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 22px;
}
.dailyWord_detai_works_img {
    padding-bottom: 15px;
}
.dailyWord_detai_works_img div{
    width: 241px;
	height: 159px;
	background-color: #FFF;
	border-radius: 10px;
	border: solid 1px #f6f6f6;
    box-sizing: border-box;
    margin-bottom: 25px;
    overflow: hidden;
    position: relative;
}
.dailyWord_detai_works_img div img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

.dailyWord_detai_works_more{
    height: 40px;
	background-color: #ffffff;
	border-radius: 10px;
	border: solid 1px #eeeeee;
    box-sizing: border-box;
	opacity: 0.9;
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 38px;
	letter-spacing: 0px;
	color: #999999;
    text-align: center;
    cursor: pointer;
}
.dailyWord_detai_works_more:hover{
    color: #5957FF;
}



.ranking_ul{
    margin: 24px -13.33px 26px;
}
.daily_li{
    width: 330px;
    height: 313px;
    border-radius: 10px;
    margin:0 13.33px 30px;
    padding: 14px 15px 0;
    box-sizing: border-box;
    background-color: #FFF;
}
.ranking_li_box{
    width: 100%;
    height: 198px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}
.ranking_li_box img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}
.ranking_li_name{
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 51px;
	letter-spacing: 0px;
	color: #333333;
}
.dailyWord_li_l{
    line-height: 36px;
    font-size: 14px;
	color: #999999;
}
.dailyWord_li_l img{
    width: 36px;
    height: 36px;
    background-color: #ffffff;
	border: solid 1px #f6f6f6;
    box-sizing: border-box;
    border-radius: 36px;
    margin-right: 8px;
}
.dailyWord_li_r{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 36px;
	letter-spacing: 0px;
	color: #999999;
}


.sidebar_r{
    right: 2%;
    left: auto;
}
</style>